<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Leonore Voets</h2>
          <p>
            Osteopathie is een prachtig vak. Vanaf het begin van de studie ben
            ik al gefascineerd door de werking van het lichaam en wat
            osteopathie kan betekenen voor mensen. Het lichaam een juiste aanzet
            geven om deze tot zelfgenezing te laten komen is en blijft boeiend.
            Wat mij als osteopaat iedere keer bezig houdt, is de vraag wat het
            lichaam nodig heeft.
          </p>
          <p>
            Voordat ik de opleiding osteopathie aan het college Sutherland in
            Amsterdam ben begonnen was ik fysiotherapeut. Vanuit mijn turn
            carrière ben ik met veel fysiotherapeuten in aanraking gekomen. Het
            menselijk lichaam heeft mij altijd al geïnteresseerd waardoor ik
            deze opleiding ben gaan volgen. Maar ik merkte dat ik meer kennis
            wilde vergaren om iedereen optimaal te kunnen behandelen. Een klacht
            kan veel verschillende oorzaken hebben. Want waarom komen bepaalde
            klachten steeds terug en waarom ontstaan sommige klachten zonder
            duidelijke oorzaak? Osteopathie ziet het lichaam als geheel en
            achterhaalt waar klachten kunnen ontstaan.
          </p>
          <p>
            Ik sport nog steeds graag, nu wissel ik verschillende sporten af
            zoals schaatsen, yoga en crossfit.
          </p>
          <h3>Opleiding en Nascholing</h3>
          <ul>
            <li>
              Fysiotherapie, hogeschool Leiden
            </li>
            <li>
              Drie-dimensionale arthrokinematische mobilisatie van de
              extremiteiten, DAM-opleidingen VOF
            </li>
            <li>
              Fysiotherapie bij COPD en astma, IC Fysio Haaglanden
            </li>
            <li>
              Ontspanningsoefeningen en ademtechniek, Pro Education
            </li>
            <li>
              Cursus dry needling extremiteiten, Uplands Physiotherapy Clinic
            </li>
            <li>
              Anatomie dissectie, bovenste extremiteit en wervelkolom,
              morphologicum Heidelberg
            </li>
            <li>
              Osteopathie, college Sutherland Amsterdam
            </li>
            <li>
              Opleiding Osteopathie bij Kinderen, Pro Osteo (start september
              2022)
            </li>
          </ul>

          <img src="../../assets/team/leonore.png" alt="Leonore" />
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../../components/Container.vue";
export default {
  name: "Leonore",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  color: #747769;
  em {
    font-style: normal;
    color: #747769;
  }
}

img {
  max-width: 100%;
}
</style>
